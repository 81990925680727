









































import {Component, Mixins, Watch} from "vue-property-decorator";
import IlPicker from "@/components/pickers/IlPicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import KotaKullanimSekliPicker from "@/components/pickers/KotaKullanimSekliPicker.vue";
import IbanKullanimTercihiPicker from "@/components/pickers/IbanKullanimTercihiPicker.vue";
import AdliyePicker from "@/components/pickers/AdliyePicker.vue";
import YetkiliKurumPicker from "@/components/pickers/YetkiliKurumPicker.vue";
import TakipMahiyetiPicker from "@/components/pickers/TakipMahiyetiPicker.vue";
import EPosta from "@/components/inputs/EPosta.vue";

@Component({
    components: {
        EPosta,
        TakipMahiyetiPicker,
        YetkiliKurumPicker, AdliyePicker, IbanKullanimTercihiPicker, KotaKullanimSekliPicker, IlPicker}
})
export default class MtsTakipAcXmlForm extends Mixins(ObjectInputMixin) {

}
