












import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class IbanKullanimTercihiPicker extends Mixins(SimpleInputMixin) {
    tercihler = ["Avukat","Müvekkil"]
}
