












import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class YetkiliKurumPicker extends Mixins(SimpleInputMixin) {
    kurumlar = ["Yeşilırmak Elektrik Parekende Satış AŞ","Yeşilırmak Elektrik Parekende Satış Anonim Şirketi"]
}
