



























import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import AdresForm from "@/components/comps/forms/AdresForm.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {HesapNumarasiEntity} from "@/entity/HesapNumarasiEntity";
import {BankaHesapListResponseProvider} from "@/services/BankaHesapService";

@Component({
    components: {AdresForm, FormDialogButton}
})
export default class IbanPicker extends Mixins(ObjectInputMixin) {
    @Prop()
    bankaHesap!:HesapNumarasiEntity;

    ibanlar:any=[];
    uyapIbanlar!:HesapNumarasiEntity[];
    mounted(){
        console.log("xml",this.bankaHesap);

        this.uyapIbanlar = BankaHesapListResponseProvider().data;
        console.log("uyap",this.uyapIbanlar);

        this.ibanlar.push({iban:this.bankaHesap, kaynak:"XML"})
        this.uyapIbanlar.forEach((item:HesapNumarasiEntity)=>{
            this.ibanlar.push({iban:item,kaynak:"UYAP"});
        })
        console.log("toplam",this.ibanlar);
    }
}

