












import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class KotaKullanimSekliPicker extends Mixins(SimpleInputMixin) {
    sekiller = ["Avukat","Kurum"]
}
