










import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {IlEntity} from "@/entity/IlEntity";
import {AxiosError, AxiosResponse} from "axios";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {AdliyeEntity} from "@/entity/AdliyeEntity";
import {IlListResponseProvider} from "@/services/IlService";

@Component({})
export default class AdliyePicker extends Mixins(ObjectInputMixin) {

    @Prop({default: null})
    il!: IlEntity | null;


   /* get message(): string {
        return (this.il ? '' : 'Lütfen İl Seçiniz');
    } */

    close() {
        this.$emit("close");
    }

    items: Array<AdliyeEntity> = [];
    adliyelerMock: Array<AdliyeEntity> = [
        {
            id: 1,
            birimAdi: "İzmir Adliye",
            il: IlListResponseProvider().data[0],
            birimId: "123123",
            orgKodu: "2132353456"
        },
        {
            id: 2,
            birimAdi: "İstanbul Adliye",
            il: IlListResponseProvider().data[2],
            birimId: "123888",
            orgKodu: "1132353456"
        },
        {
            id: 3,
            birimAdi: "Ankara Adliye",
            il: IlListResponseProvider().data[1],
            birimId: "123199",
            orgKodu: "3132353456"
        }
    ]

    mounted() {
        this.loadAdliyeler();
        console.log("item:", this.items);
        console.log("il ne:", this.il);
    }

    @Watch('il')
    onIlChanged(newVal: IlEntity | null, oldVal: IlEntity | null) {
        console.log('onIlChanged from', newVal, 'to', oldVal);
        if (newVal == null) {
            this.items = [];
        }
        this.loadAdliyeler();

    }

    loadAdliyeler() {
        if (!this.il) {
            this.items = [];
            return;
        }
        console.log("items:",this.items);
        console.log("mock adliye:",this.adliyelerMock);
        this.items = this.adliyelerMock.filter((item) => item.il?.id == this.il?.id);
        return;

        if (this.il) {
            this.$http.get('/api/pickList/il/' + this.il?.id + '/adliye').then((response: AxiosResponse<Array<AdliyeEntity>>) => {
                this.items = response.data;
            }).catch((err: AxiosError) => {

            });
        }
    }

}
