





















































import {Component, Prop, Vue} from "vue-property-decorator";
import AdresPicker from "@/components/eicrapro/AdresPicker.vue";
import IbanPicker from "@/components/eicrapro/IbanPicker.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";

@Component({
    components: {IbanPicker, AdresPicker}
})
export default class UyapTakipAcAlacakKalemleri extends Vue {
    Toplam:number = 0;
    @Prop()
    items!: any;

    mounted() {
        console.log("Alacak Kalemleri: ", this.items);
        this.items.forEach((alacakKalemi: any) => {
            this.Toplam += parseFloat(alacakKalemi.meblagi);
        })
    }

}
