



























































































import {Component, Prop, Vue} from "vue-property-decorator";
import UyapTakipAcXmlForm from "@/components/eicrapro/UyapTakipAcXmlForm.vue";
import UyapTakipAcTakipListe from "@/components/eicrapro/UyapTakipAcTakipListe.vue";
import MtsTakipAcXmlForm from "@/components/eicrapro/MtsTakipAcXmlForm.vue";
import MtsTakipAcTakipListe from "@/components/eicrapro/MtsTakipAcTakipListe.vue";

@Component({
    components: {MtsTakipAcTakipListe, MtsTakipAcXmlForm, UyapTakipAcTakipListe, UyapTakipAcXmlForm}
})
export default class MtsTakipAc extends Vue {
    step: number = 1;

}
