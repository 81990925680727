












































import {Component, Mixins, Watch} from "vue-property-decorator";
import IlPicker from "@/components/pickers/IlPicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {IlEntity} from "@/entity/IlEntity";
import KotaKullanimSekliPicker from "@/components/pickers/KotaKullanimSekliPicker.vue";
import IbanKullanimTercihiPicker from "@/components/pickers/IbanKullanimTercihiPicker.vue";
import AdliyePicker from "@/components/pickers/AdliyePicker.vue";
import {AdliyeEntity} from "@/entity/AdliyeEntity";

@Component({
    components: {AdliyePicker, IbanKullanimTercihiPicker, KotaKullanimSekliPicker, IlPicker}
})
export default class UyapTakipAcXmlForm extends Mixins(ObjectInputMixin) {

}
