































import {Component, Prop, Vue} from "vue-property-decorator";
import AdresPicker from "@/components/eicrapro/AdresPicker.vue";
import IbanPicker from "@/components/eicrapro/IbanPicker.vue";

@Component({
    components: {IbanPicker, AdresPicker}
})
export default class UyapTakipAcDetay extends Vue {
@Prop()
value!:any;
mounted(){
    console.log("dosya: ",this.value);
}

}
