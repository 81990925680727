var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[(_vm.Dosyalar!=null)?_c('v-data-table',{staticClass:"table table-striped",attrs:{"headers":_vm.headers,"items":_vm.Dosyalar,"search":_vm.search,"show-select":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.alacaklilar",fn:function(ref){
var item = ref.item;
return [(item.alacaklilar.length > 4)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ul',_vm._g({},on),[_vm._l((3),function(index){return _c('li',[_vm._v(" "+_vm._s(item.alacaklilar[index-1].adSoyad)+" ")])}),_c('li',[_vm._v("...")])],2)]}}],null,true)},[_c('ul',_vm._l((item.alacaklilar),function(alacakli){return _c('li',[_vm._v(_vm._s(alacakli.adSoyad))])}),0)])]:_c('ul',_vm._l((item.alacaklilar),function(alacakli){return _c('li',[_vm._v(_vm._s(alacakli.adSoyad))])}),0)]}},{key:"item.borclular",fn:function(ref){
var item = ref.item;
return [(item.borclular.length > 4)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ul',_vm._g({},on),[_vm._l((3),function(index){return _c('li',[_vm._v(" "+_vm._s(item.borclular[index-1].adSoyad)+" ")])}),_c('li',[_vm._v("...")])],2)]}}],null,true)},[_c('ul',_vm._l((item.borclular),function(borclu){return _c('li',[_vm._v(_vm._s(borclu.adSoyad))])}),0)])]:_c('ul',_vm._l((item.borclular),function(borclu){return _c('li',[_vm._v(_vm._s(borclu.adSoyad))])}),0)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"light-blue darken-4","icon":""}},[_c('v-icon',[_vm._v("mdi-file-check-outline")])],1),_c('v-btn',{attrs:{"color":"red darken-4","icon":""}},[_c('v-icon',[_vm._v("mdi-file-hidden")])],1),_c('form-dialog-button',{attrs:{"medium":"","width":"1200","color":"light-blue darken-4","title":"Taraf Bilgileri","icon":"mdi-eye-outline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('uyap-takip-ac-detay',{on:{"input":onInput},model:{value:(_vm.Dosyalar[_vm.Dosyalar.indexOf(item)]),callback:function ($$v) {_vm.$set(_vm.Dosyalar, _vm.Dosyalar.indexOf(item), $$v)},expression:"Dosyalar[Dosyalar.indexOf(item)]"}})]}}],null,true)}),_c('form-dialog-button',{attrs:{"medium":"","width":"1200","color":"light-blue darken-4","title":"Alacak Kalemleri","icon":"mdi-cash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('uyap-takip-ac-alacak-kalemleri',{attrs:{"items":item.alacaklar}})]}}],null,true)})]}},{key:"item.durum",fn:function(ref){
var item = ref.item;
return [(item.durum===0)?_c('v-progress-circular',{attrs:{"width":2,"size":25,"color":"primary","indeterminate":""}}):_vm._e(),(item.durum===1)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_vm._e(),(item.durum===2)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-alert-circle-outline ")]):_vm._e(),(item.durum===3)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item.aciklama",fn:function(ref){
var item = ref.item;
return [(item.durum===0)?_c('span',[_vm._v(" -")]):_vm._e(),(item.durum===1)?_c('span',[_c('kisa-metin',{attrs:{"length":15},model:{value:(item.durumAciklama),callback:function ($$v) {_vm.$set(item, "durumAciklama", $$v)},expression:"item.durumAciklama"}})],1):_vm._e(),(item.durum===2)?_c('span',[_c('kisa-metin',{attrs:{"length":15},model:{value:(item.durumAciklama),callback:function ($$v) {_vm.$set(item, "durumAciklama", $$v)},expression:"item.durumAciklama"}})],1):_vm._e(),(item.durum===3)?_c('span',[_vm._v("-")]):_vm._e()]}}],null,false,2389144952)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }