



























import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import AdresForm from "@/components/comps/forms/AdresForm.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {AdresEntity} from "@/entity/AdresEntity";
import {AdresListResponseProvider} from "@/services/AdresService";

@Component({
    components: {AdresForm, FormDialogButton}
})
export default class AdresPicker extends Mixins(ObjectInputMixin) {
   @Prop()
    adres!:AdresEntity;

   adresler:any=[];
   uyapAdresler!:AdresEntity[];
   mounted(){
        console.log("xml",this.adres);

       this.uyapAdresler = AdresListResponseProvider().data;
       console.log("uyap",this.uyapAdresler);

       this.adresler.push({adres:this.adres, kaynak:"XML"})
       this.uyapAdresler.forEach((item:AdresEntity)=>{
           this.adresler.push({adres:item,kaynak:"UYAP"});
       })
       console.log("toplam",this.adresler);
   }
}

