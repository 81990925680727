

































































































import {Component, Vue} from "vue-property-decorator";
import {AdresListResponseProvider} from "@/services/AdresService";
import {BankaHesapListResponseProvider} from "@/services/BankaHesapService";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import UyapTakipAcDetay from "@/components/eicrapro/UyapTakipAcDetay.vue";
import UyapTakipAcAlacakKalemleri from "@/components/eicrapro/UyapTakipAcAlacakKalemleri.vue";
import KisaMetin from "@/components/comps/tools/KisaMetin.vue";



@Component({
    components: {KisaMetin, UyapTakipAcAlacakKalemleri, UyapTakipAcDetay, FormDialogButton}
})
export default class UyapTakipAcTakipListe extends Vue {

    adres1 = AdresListResponseProvider().data[0];
    iban1 = BankaHesapListResponseProvider().data[1];
    IlamsizList=[
        {
            id :0,
            ilamsizTipi :"diger",
            alacakNo :"",
            meblagi :"1428.16",
            meblagTuru :"PRBRMTL",
            meblagTuruAciklama :"TL - Türk Lirası",
            aciklama :"Kredi Sözleşmesi/leri,İhtarname/leri",
            alacakTarihi :null,
            alacakKalemleri :[
                {
                    selectedTarafList :"0,1",
                    temelBilgileri :{
                        alacakTutari :"1257.92",
                        selectedParaBirimi :"PRBRMTL",
                        selectedParaBirimiAciklama :"TL - Türk Lirası",
                        KDV :false,
                        aciklama :"Ana Para (Temerrüt Faiz Oranı %25.74'dır.)",
                        selectedTarihTuru :"",
                        detayliAciklama :"Ana Para (Temerrüt Faiz Oranı %25.74'dır.)",
                        selectedAlacakKalemKodu :{
                            alacakKalemKodAciklama :"Ana Para (Temerrüt Faiz Oranı %25.74'dır.)",
                            alacakKalemKod :"3"
                        }
                    },
                    faizBilgileri :{
                        selectedFaizTuru :{
                            tktId :"FAIZT00003",
                            aciklama :"Diğer"
                        },
                        faizOrani :"25.74",
                        selectedFaizSureTipi :"2",
                        selectedFaizSureTipiAciklama :"Yıllık"
                    }
                },
                {
                    selectedTarafList :"0,1",
                    temelBilgileri :{
                        alacakTutari :"150.08",
                        selectedParaBirimi :"PRBRMTL",
                        selectedParaBirimiAciklama :"TL - Türk Lirası",
                        KDV :false,
                        aciklama :"Akdi Faiz % 19.8 (07/07/2020-05/10/2020)",
                        selectedTarihTuru :"",
                        detayliAciklama :"Akdi Faiz % 19.8 (07/07/2020-05/10/2020)",
                        selectedAlacakKalemKodu :{
                            alacakKalemKodAciklama :"Akdi Faiz % 19.8 (07/07/2020-05/10/2020)",
                            alacakKalemKod :"6"
                        }
                    },
                    faizBilgileri :{

                    }
                },
                {
                    selectedTarafList :"0,1",
                    temelBilgileri :{
                        alacakTutari :"0.69",
                        selectedParaBirimi :"PRBRMTL",
                        selectedParaBirimiAciklama :"TL - Türk Lirası",
                        KDV :false,
                        aciklama :"İşlemiş % 19.8 Faiz  (05/10/2020-06/10/2020)",
                        selectedTarihTuru :"",
                        detayliAciklama :"İşlemiş % 19.8 Faiz  (05/10/2020-06/10/2020)",
                        selectedAlacakKalemKodu :{
                            alacakKalemKodAciklama :"İşlemiş % 19.8 Faiz  (05/10/2020-06/10/2020)",
                            alacakKalemKod :"6"
                        }
                    },
                    faizBilgileri :{

                    }
                },
                {
                    selectedTarafList :"0,1",
                    temelBilgileri :{
                        alacakTutari :"11.94",
                        selectedParaBirimi :"PRBRMTL",
                        selectedParaBirimiAciklama :"TL - Türk Lirası",
                        KDV :false,
                        aciklama :"İhtarname Masrafı",
                        selectedTarihTuru :"",
                        detayliAciklama :"İhtarname Masrafı",
                        selectedAlacakKalemKodu :{
                            alacakKalemKodAciklama :"İhtarname Masrafı",
                            alacakKalemKod :"5"
                        }
                    },
                    faizBilgileri :{

                    }
                },
                {
                    selectedTarafList :"0,1",
                    temelBilgileri :{
                        alacakTutari :"7.53",
                        selectedParaBirimi :"PRBRMTL",
                        selectedParaBirimiAciklama :"TL - Türk Lirası",
                        KDV :false,
                        aciklama :"BSMV %5 Toplamı",
                        selectedTarihTuru :"",
                        detayliAciklama :"BSMV %5 Toplamı",
                        selectedAlacakKalemKodu :{
                            alacakKalemKodAciklama :"BSMV %5 Toplamı",
                            alacakKalemKod :"6"
                        }
                    },
                    faizBilgileri :{

                    }
                }
            ]
        }
    ]
    IlamsizList2=[
        {
            id :0,
            ilamsizTipi :"diger",
            alacakNo :"1",
            meblagi :"2450.00",
            meblagTuru :"PRBRMTL",
            meblagTuruAciklama :"TL - Türk Lirası",
            aciklama :"VİPORT 2 SİTE YÖNETİMİ TARAFINDAN ALINAN 16.05.2019 TARİH 9 NOLU KARARDA BELİRTİLEN VE BORÇLU TARAFINDAN ÖDENMEYEN ORTAK GİDER ÖDEMESİ  04.01.2020 TARİH 2020/1 NOLU KARARDA BELİRTİLEN VE BORÇLU TARAFINDAN ÖDENMEYEN ORTAK GİDER ÖDEMESİ",
            alacakTarihi :"01/08/2019",
            alacakKalemleri :[
                {
                    selectedTarafList :"0,1",
                    temelBilgileri :{
                        alacakTutari :"2450.00",
                        selectedParaBirimi :"PRBRMTL",
                        selectedParaBirimiAciklama :"TL - Türk Lirası",
                        KDV :false,
                        aciklama :"ASIL ALACAK",
                        selectedTarihTuru :"",
                        detayliAciklama :"ASIL ALACAK",
                        selectedAlacakKalemKodu :{
                            alacakKalemKodAciklama :"ASIL ALACAK",
                            alacakKalemKod :"3"
                        }
                    },
                    faizBilgileri :{
                        selectedFaizTuru :{
                            tktId :"FAIZT00003",
                            aciklama :"Diğer"
                        },
                        faizOrani :"5.00",
                        selectedFaizSureTipi :"1",
                        selectedFaizSureTipiAciklama :"Aylık"
                    }
                },
                {
                    selectedTarafList :"0,1",
                    temelBilgileri :{
                        alacakTutari :"1768.08",
                        selectedParaBirimi :"PRBRMTL",
                        selectedParaBirimiAciklama :"TL - Türk Lirası",
                        KDV :false,
                        aciklama :"İşlemiş Faiz 01/08/2019-14/10/2020 arası 433 Gün %5,00",
                        selectedTarihTuru :"",
                        detayliAciklama :"İşlemiş Faiz 01/08/2019-14/10/2020 arası 433 Gün %5,00",
                        selectedAlacakKalemKodu :{
                            alacakKalemKodAciklama :"İşlemiş Faiz 01/08/2019-14/10/2020 arası 433 Gün %5,00",
                            alacakKalemKod :"6"
                        }
                    },
                    faizBilgileri :{

                    }
                }
            ]
        },
        {
            id :1,
            ilamsizTipi :"diger",
            alacakNo :"2",
            meblagi :"3650.00",
            meblagTuru :"PRBRMTL",
            meblagTuruAciklama :"TL - Türk Lirası",
            aciklama :"VİPORT 2 SİTE YÖNETİMİ TARAFINDAN ALINAN 16.05.2019 TARİH 9 NOLU KARARDA BELİRTİLEN VE BORÇLU TARAFINDAN ÖDENMEYEN ORTAK GİDER ÖDEMESİ  04.01.2020 TARİH 2020/1 NOLU KARARDA BELİRTİLEN VE BORÇLU TARAFINDAN ÖDENMEYEN ORTAK GİDER ÖDEMESİ",
            alacakTarihi :"01/07/2020",
            alacakKalemleri :[
                {
                    selectedTarafList :"0,1",
                    temelBilgileri :{
                        alacakTutari :"3650.00",
                        selectedParaBirimi :"PRBRMTL",
                        selectedParaBirimiAciklama :"TL - Türk Lirası",
                        KDV :false,
                        aciklama :"ASIL ALACAK",
                        selectedTarihTuru :"",
                        detayliAciklama :"ASIL ALACAK",
                        selectedAlacakKalemKodu :{
                            alacakKalemKodAciklama :"ASIL ALACAK",
                            alacakKalemKod :"3"
                        }
                    },
                    faizBilgileri :{
                        selectedFaizTuru :{
                            tktId :"FAIZT00003",
                            aciklama :"Diğer"
                        },
                        faizOrani :"5.00",
                        selectedFaizSureTipi :"1",
                        selectedFaizSureTipiAciklama :"Aylık"
                    }
                },
                {
                    selectedTarafList :"0,1",
                    temelBilgileri :{
                        alacakTutari :"626.58",
                        selectedParaBirimi :"PRBRMTL",
                        selectedParaBirimiAciklama :"TL - Türk Lirası",
                        KDV :false,
                        aciklama :"İşlemiş Faiz 01/07/2020-14/10/2020 arası 103 Gün %5,00",
                        selectedTarihTuru :"",
                        detayliAciklama :"İşlemiş Faiz 01/07/2020-14/10/2020 arası 103 Gün %5,00",
                        selectedAlacakKalemKodu :{
                            alacakKalemKodAciklama :"İşlemiş Faiz 01/07/2020-14/10/2020 arası 103 Gün %5,00",
                            alacakKalemKod :"6"
                        }
                    },
                    faizBilgileri :{

                    }
                }
            ]
        }
    ]
    Dosyalar = [
        {
            id:1, icraDairesi:"İzmir 1. İcra Dairesi", borclular:[{id:1, adSoyad:"Nadir Çiftçi",tcKimlikNo:"11111111110", adres:this.adres1},{id:2, adSoyad:"Süleyman Soba",tcKimlikNo:"11111111110", adres:this.adres1}],alacaklilar:[{id:1,adSoyad:"Kaan Koparan",tcKimlikNo:"11111111110", adres:this.adres1, iban:this.iban1}],alacaklar:this.IlamsizList ,harcMasrafTutari:63.8, durum:0,durumAciklama:"",
        },
        {
            id:2, icraDairesi:"İzmir 2. İcra Dairesi", borclular:[{id:1, adSoyad:"Osman Mehmetoğlu", adres:this.adres1,tcKimlikNo:"11111111110"}],alacaklilar:[{id:1,adSoyad:"Kaan Koparan", adres:this.adres1,tcKimlikNo:"11111111110", iban:this.iban1}],alacaklar:this.IlamsizList2,harcMasrafTutari:63.8, durum:1,durumAciklama:"Adres Bilgileri Sorgulanamadı",
        },
        {
            id:3, icraDairesi:"İzmir 3. İcra Dairesi", borclular:[{id:1, adSoyad:"Aziz Polat", adres:this.adres1,tcKimlikNo:"11111111110"},{id:2, adSoyad:"Adnan Yıldırım", adres:this.adres1,tcKimlikNo:"11111111110"}],alacaklilar:[{id:1,adSoyad:"Kaan Koparan", adres:this.adres1,tcKimlikNo:"11111111110", iban:this.iban1}],alacaklar:this.IlamsizList2,harcMasrafTutari:63.8, durum:2,durumAciklama:"Adres Seçili Değil"
        },
        {
            id:4, icraDairesi:"İzmir 3. İcra Dairesi", borclular:[{id:1, adSoyad:"Turgut Erdoğan", adres:this.adres1,tcKimlikNo:"11111111110"},{id:2, adSoyad:"Adnan Bahçeli", adres:this.adres1,tcKimlikNo:"11111111110"}],alacaklilar:[{id:1,adSoyad:"Kaan Koparan", adres:this.adres1,tcKimlikNo:"11111111110", iban:this.iban1}],alacaklar:this.IlamsizList,harcMasrafTutari:63.8, durum:3,durumAciklama:""
        },
    ]
    headers = [
        {text: "Alacaklılar", value: "alacaklilar", sortable: false},
        {text: "Borçlular", value: "borclular", sortable: false},
        {text: "İcra Dairesi", value: "icraDairesi", sortable: true},
        {text: "Harç Masraf Tutarı", value: "harcMasrafTutari", sortable: false},
        {text: "Vekalet Dayanak Detay", value: "actions", sortable: false},
        {text: "Durum", value: "durum", sortable: false},
        {text: "Açıklama", value: "aciklama", sortable: false},

    ];

}
