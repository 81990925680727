












import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class YetkiliKurumPicker extends Mixins(SimpleInputMixin) {
    mahiyetler = ["Telefon","Doğal Gaz","Su","Elektrik","İnternet","Diğer",]
}
